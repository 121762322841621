/*全局字体*/
@font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/*字体大小*/
@font-size-base: 14px;

/*行高*/
@line-height-base: 1.5;

/*间距大小*/
@xs: 8px;
@sm: 16px;
@defaultSize: 24px;
@lg: 32px;
@xl: 40px;

/*字体颜色*/
@text-color: fade(#000, 85%);

/*品牌色*/
@primary-color: #204fdd;

/*hover*/
@primaryHover-color: #3764ea;


/*链接颜色*/
@link-color: #1890ff;
@link-color-hover: #c4d0ff;

/*暗色链接颜色*/
@black-link-color: @foot-color;
@black-link-color-hover: @link-color;

/*白色*/
@white-color: #ffffff;

/*高亮色*/
@highlight-color: #ff9a1b;

/*默认背景色*/
@component-background: #fff;

/*灰阶*/
@gray-one-color: #333333;
@gray-two-color: #666666;
@text: rgba(0,0,0,0.45);
@gray-four-color: #bdbdbd;
@gray-five-color: #e6e6e6;

/*红色*/
@error-color: #cf2525;

/*默认背景色*/
@default-background: #06060b;

/*头部背景色*/
@nav-background: @default-background;

/*头部字体颜色*/
@nav-color: @white-color;

/*头部链接字体颜色*/
@nav-link-color: @black-link-color;

/*头部链接hover颜色*/
@nav-link-color-hover: @black-link-color-hover;

/*底部背景色*/
@foot-background: @default-background;

/*底部字体颜色*/
@foot-color: #60606d;

/*底部字体可点击颜色*/
@foot-link-color: @black-link-color;

/*底部字体可点击hover颜色*/
@foot-link-color-hover: @black-link-color-hover;
